import React from "react";
import { Row, Col } from "antd";
import FeatureItem from "./FeatureItem";
export default class Feature extends React.Component {
  render() {
    const {data}= this.props;
    return (
      <div className="mb-3">
        <Row>
          <Col span={12} xs={24} sm={12} md={12}>
            <FeatureItem
              thumbnail={data[0].feature_imgurl}
              title={data[0].thread_title}
              time={data[0].post_date}
              username={data[0].username}
              id={data[0].thread_id}
              view={data[0].view_count}
            />
          </Col>
          <Col span={12} xs={24} sm={12} md={12}>
            <FeatureItem
              thumbnail={data[1].feature_imgurl}
              title={data[1].thread_title}
              time={data[1].post_date}
              username={data[1].username}
              id={data[1].thread_id}
              view={data[1].view_count}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8} xs={24} sm={12} md={8} lg={8}>
            <FeatureItem
              thumbnail={data[2].feature_imgurl}
              title={data[2].thread_title}
              time={data[2].post_date}
              username={data[2].username}
              id={data[2].thread_id}
              view={data[2].view_count}
            />
          </Col>
          <Col span={8} xs={24} sm={12} md={8} lg={8}>
            <FeatureItem
              thumbnail={data[3].feature_imgurl}
              title={data[3].thread_title}
              time={data[3].post_date}
              username={data[3].username}
              id={data[3].thread_id}
              view={data[3].view_count}
            />
          </Col>
          <Col span={8} xs={24} sm={24} md={8} lg={8}>
            <FeatureItem
              thumbnail={data[4].feature_imgurl}
              title={data[4].thread_title}
              time={data[4].post_date}
              username={data[4].username}
              id={data[4].thread_id}
              view={data[4].view_count}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
