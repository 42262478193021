import React, { Component } from "react";
import HotTopicItem from "./HotTopicItem";


const topics = [
  {
    title: 'ASUS AI',
    id: 99,
    image: 'https://i.imgur.com/MEtMyYt.jpeg'
  },
  {
    title: 'Vivobook',
    id: 77,
    image: 'https://i.imgur.com/gq6xn90.jpeg'
  },
  {
    title: 'Zenbook',
    id: 76,
    image: 'https://i.imgur.com/hoZT0Wm.jpeg'
  },
  {
    title: 'ROG',
    id: 90,
    image: 'https://i.imgur.com/AteJZ3v.png'
  },
  {
    title: 'ASUS Gaming',
    id: 89,
    image: 'https://i.imgur.com/ixIiKMp.jpeg'
  },
  {
    title: 'Góc công nghệ',
    id: 42,
    image: 'https://i.imgur.com/Jq6poW3.jpeg'
  }
]
export default class HotTopic extends Component {
  render() {
    return (
      <div className="hot-topic d-none d-sm-block">
        <p className="hot-topic-title">HOT TOPIC</p>
        {topics.map((topic, index) => (
          <HotTopicItem
            key={index}
            {...topic}
          />
        ))}
      </div>
    );
  }
}
