import React, { Component } from "react";
import Home from "./containers/Home";
import { Spin } from "antd";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }
  render() {
    return <div>{this.state.isLoading ? <Spin size="large" tip="Đang tải... bạn chờ xíu nhé" className="spinner" /> : <Home />}</div>;
  }
}

