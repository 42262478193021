import React, { Component } from "react";
import Article from "./Article.js";
import { Row, Col, Icon, Avatar, Button } from "antd";

export default class ArticleList extends Component {
  render() {
    return (
      <div className="list-article">
        {this.props.data.map(thread=>{
          return (
            <Article
              thumbnail={thread.feature_imgurl}
              category={thread.node_title}
              title={thread.thread_title}
              username={thread.username}
              avatar={thread.avatar}
              view={thread.view_count}
              time={thread.post_date}
              key={thread.thread_id}
              id={thread.thread_id}
              node_id={thread.node_id}
            />
          );
        })}
      </div>
    );
  }
}
