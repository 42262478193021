import React, { Component } from "react";
import "../theme/custom.css";
import MediaQuery from "react-responsive";
import { Row, Col, Icon, Avatar, Button } from "antd";
import { BACK_END_URL } from "../util/constant"
import moment from "moment";
export default class Article extends Component {
  render() {
    return (
      <Row className="article mb-1" gutter={16}>
        <a href={`${BACK_END_URL}/threads/${this.props.id}`}>
          <Col span={6} md={6} sm={8} xs={24} className="col-thumb">
            <img
              src={this.props.thumbnail}
              alt="Thumbnail"
              className="article-thumb"
            />
          </Col>
          <Col span={18} md={18} sm={16} xs={24} className="article-content">
            <div className="article-description">
              <h6 className="mt-2 title-post">{this.props.title}</h6>
              <p className="d-none d-sm-block">
                <a class="category" href={`${BACK_END_URL}/forums/${this.props.node_id}`}>
                  {this.props.category}
                </a>
              </p>
            </div>
            <div className="article-info mt-4">
              <div>
                <Avatar
                  className="mr-2"
                  size={18}
                  src={this.props.avatar}
                />
                <span className="text-primary font-weight-bold">
                  {this.props.username}
                </span>
              </div>

              <div className="sub-info">
                <span className="text-secondary d-sm-none d-md-block mr-4">
                  {moment.unix(this.props.time).format("DD/MM/YYYY")}
                </span>
                <span className="text-secondary ">
                  {this.props.view} xem
                    </span>
              </div>
            </div>
            {/* <MediaQuery className="article-footer" maxWidth={1023}>
                <Row className="article-info" type="flex" justify="start">
                  <Col span={8}>
                    <span className="text-primary font-weight-bold">
                      {this.props.username}
                    </span>
                  </Col>
                  <Col span={8}>
                    <span className="text-secondary d-sm-none d-md-block">
                      {moment.unix(this.props.time).format("DD/MM/YYYY")}
                    </span>
                  </Col>
                  <Col span={8}>
                    <span className="text-secondary ">
                      {this.props.view} xem
                    </span>
                  </Col>
                </Row>
              </MediaQuery> */}
            {/* <Button type="link" className="mb-2">
                  <Icon type="share-alt" style={{ fontSize: 20 }} />
                </Button> */}
          </Col>
        </a>
      </Row>
    );
  }
}
