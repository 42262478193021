import { BACK_END_URL } from "../util/constant";
import moment from "moment";
import MediaQuery from "react-responsive";
import React, { Component } from "react";
import { Row, Col, Card, Avatar } from "antd";
const { Meta } = Card;

export default class FeatureItem extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <div className="mt-2 mx-1">
        <a href={`${BACK_END_URL}/threads/${this.props.id}`}>
          <Card
            size="small"
            hoverable={true}
            bordered={false}
            className="feature-item"
            cover={
              <figure class="feature-thumb">
                <img
                  src={`${this.props.thumbnail}`}
                  alt="Video: Lincoln Center Festival 2017: George Balanchine's JEWELS"
                />
              </figure>
            }
          >
            <h6>{this.props.title}</h6>
            <div className="sub-info">
              <span className="text-primary font-weight-bold">
                {this.props.username} &nbsp;
              </span>
             
              <span className="text-secondary">
                <span className="text-secondary">{moment.unix(this.props.time).format("DD/MM/YYYY")}</span>
                <MediaQuery maxWidth={425}> <span className="ml-4">{this.props.view} xem</span></MediaQuery>
              </span>
            </div>
          </Card>
        </a>
      </div>
    );
  }
}
