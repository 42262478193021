import React, { Component } from "react";
import { Avatar, Col, Row } from "antd";
import { BACK_END_URL } from "../util/constant"
export default class HotTopicItem extends Component {
  render() {
    return (
      <div className="hot-topic-item">
        <a href={`${BACK_END_URL}`}>
          <a href={`${BACK_END_URL}/forums/${this.props.id}`}>
            <Row gutter={10}>
              <Col span={10} sm={12}>
                <img className="hot-topic-thumb" src={this.props.image} />
              </Col>
              <Col
                span={14}
                sm={12}
                style={{
                  display: "flex"
                }}
              >
                <b className="hot-topic-info">{this.props.title}</b>
                {/* <p className="text-secondary">1444 chủ đề </p> */}
              </Col>
            </Row>
          </a>
        </a>
      </div>
    );
  }
}
