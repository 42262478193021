const screenSize = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
};

const BACK_END_URL = "https://zentalk.vn";

const forums = [
  {
    name:"",
    url:"",
    count:""
  }
]
export {
  screenSize,
  BACK_END_URL
};
