import React, { Component } from "react";
import ArticleList from "../components/ArticleList.js";
import "../theme/custom.css";
import { Col, Row, Button, Spin } from "antd";
import HotTopic from "../components/HotTopic";
import Feature from "../components/Feature";
import Video from "../components/Video";
import axios from "axios";
import { BACK_END_URL } from "../util/constant";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      threads: [],
      top_threads: [],
      thread_answer: [],
      page: 1,
      perPage: 20,
      isLoad: true,
      indexQuestion: Math.floor(Math.random() * 4 + 0),
      isThreadLoad: false,
      videos:[],
      isDisable: false
    };
  }
  async componentDidMount() {
    const res = await axios.get(
      `${BACK_END_URL}/api/index.php?page=${this.state.page}&perPage=${this.state.perPage}`
    );
    const data = res.data;
    await this.setState(
      {
        threads: data.threads.data.slice(5, data.threads.data.length),
        top_threads: data.threads.data.slice(0,5),
        thread_answer: data.thread_answer,
        videos: data.videos
      },
      () => {
        this.setState({
          isLoad: false
        });
      }
    );
    console.log(this.state);
  }
  fetchData = async () => {
    this.setState({
      isThreadLoad : true
    })
    const res = await axios.get(
      `${BACK_END_URL}/api/index.php?page=${this.state.page}&perPage=${this.state.perPage}`
    );
this.setState(prevState => ({
  threads: [...prevState.threads, ...res.data.threads.data]
}));

    this.setState({
      isThreadLoad: false
    });
    if (res.data.threads.data.length<this.state.perPage){
      this.setState({
        isDisable:true
      })
    }
  };

  render() {
    return (
      <div className="root">
        <div className="home">
          {!this.state.isLoad ? (
            <>
              <Feature data={this.state.top_threads} />
              <Row gutter={20}>
                <Col md={7} sm={12} className="d-sm-none d-md-block">
                  <HotTopic />
                </Col>
                <Col
                  md={17}
                  sm={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column"
                  }}
                >
                  <ArticleList data={this.state.threads} />
                  

                  <Button
                    type="primary"
                    style={{ width: 145 }}
                    shape="round"
                    size="large"
                    className="mt-2 button-load"
                    loading={this.state.isThreadLoad}
                    disabled={this.state.isDisable}
                    onClick={async () => {
                      await this.setState({
                        page: this.state.page + 1
                      });
                      await this.fetchData();
                    }}
                  >
                    Xem thêm
                  </Button>
                  
                </Col>
              </Row>
              <Row>
                <Col sm={7} md={7} lg={7} xs={24}></Col>

                <Col sm={17} md={17} lg={17} xs={24}>
                 
                  <Video data={this.state.videos} />
                </Col>
              </Row>
            </>
          ) : (
            <Spin size="large"></Spin>
          )}
        </div>
      </div>
    );
  }
}
