import React from "react";
import ItemsCarousel from "react-items-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import MediaQuery from "react-responsive";
// const data = [
//   "oGMd4zst5OQ",
//   "sIPy6bY9dfI",
//   "SH8mQlbB0Jw",
//   "MO6Cz-WQEzw",
//   "tqpxaKcF88U"
// ];
export default class Video extends React.Component {
  constructor(props)
  {
    super(props);
    this.state = {
      video : this.props.data[0]
    }
  }
 
  onClickItem = (id)=>{
    console.log(id)
    this.setState({
      video:id
    })
  }

  render() {
    
 
    return (
      <div className=" mt-3 mb-3 video">
        <div className="d-flex align-content-center align-items-center">
          <img
            className="mb-2 ml-1"
            width="42"
            height="42"
            alt="youtube logo"
            src="https://i.imgur.com/VPb1Ns9.png"
          />
          <h5 className="ml-1 mt-1">Youtube</h5>
        </div>
        <div>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              title="Video Zentalk"
              class="embed-responsive-item"
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${this.state.video}`}
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
            />
          </div>
          <div className="list-video">
            <MediaQuery maxWidth={767}>
              <Slider
                {...{
                  dots: true,
                  infinite: true,
                  speed: 500,
                  slidesToShow: 2,
                  slidesToScroll: 1
                }}
              >
                {this.props.data.map(item => {
                  return (
                    <div>
                      <img
                        className="video-item"
                        alt="video zentalk"
                        src={`https://i.ytimg.com/vi/${item}/mqdefault.jpg`}
                        onClick={() => {
                          this.onClickItem(item);
                        }}
                      />
                    </div>
                  );
                })}
              </Slider>
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <Slider
                {...{
                  dots: true,
                  infinite: true,
                  speed: 500,
                  slidesToShow: 3,
                  slidesToScroll: 1
                }}
              >
                {this.props.data.map(item => {
                  return (
                    <div>
                      <img
                        className="video-item"
                        alt="video zentalk"
                        src={`https://i.ytimg.com/vi/${item}/mqdefault.jpg`}
                        onClick={() => {
                          this.onClickItem(item);
                        }}
                      />
                    </div>
                  );
                })}
              </Slider>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
              <Slider
                {...{
                  dots: true,
                  infinite: true,
                  speed: 500,
                  slidesToShow: 4,
                  slidesToScroll: 1
                }}
              >
                {this.props.data.map(item => {
                  return (
                    <div>
                      <img
                        className="video-item"
                        alt="video zentalk"
                        src={`https://i.ytimg.com/vi/${item}/mqdefault.jpg`}
                        onClick={() => {
                          this.onClickItem(item);
                        }}
                      />
                    </div>
                  );
                })}
              </Slider>
            </MediaQuery>
          </div>
        </div>
      </div>
    );
  }
}
